<template>
  <CreateEditUserModal
    v-if="showCreateEditUserModal"
    :userId="userIdSelected"
    @closeModal="closeCreateEditUserModal"
  />
  <LoadingData v-if="loadData" />
  <div v-else>
    <div class="card mb-0 mb-md-3">
      <div class="card-header d-flex align-items-center py-0">
        <h6 class="py-3 mb-0"><i class="ph-user me-2" />Informações</h6>
        <div class="ms-auto my-auto">
          <router-link
            to="/users/unities"
            type="button"
            class="btn btn-primary"
          >
            Selecionar Outra Unidade
          </router-link>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <p><b>Nome:</b> {{ user.name }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p><b>CPF/CNPJ:</b> {{ user.cgccfo }}</p>
          </div>
          <div class="col-12 col-md-6">
            <p><b>Nascimento:</b> {{ dateTimeSqlToDateBR(user.birthday) }}</p>
          </div>
          <div class="col-12">
            <p>
              <b>Endereço:</b> {{ user.street }}, {{ user.streetNumber }}
              {{ user.streetComplement }} {{ user.district }} - {{ user.city }}.
              CEP: {{ user.cep }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <UpdatePassword :user="user"></UpdatePassword>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import UpdatePassword from '@/components/users/UpdatePassword.vue'
import { dateTimeSqlToDateBR } from '@/utils/date'

export default {
  name: 'Users',

  components: {
    LoadingData,
    UpdatePassword,
  },

  data() {
    return {
      loadData: true,
      showCreateEditUserModal: false,
      user: [],
      userIdSelected: null,
      dateTimeSqlToDateBR,
    }
  },

  methods: {
    async getUser() {
      try {
        this.user = await this.$store.dispatch(
          'getUser',
          this.$store.getters.user.id,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getUser()
  },
}
</script>

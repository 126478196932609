<template>
  <div class="row">
    <div class="col-12 col-md-6" v-for="unity in unities" :key="unity.id">
      <div
        class="card"
        id="bg-current"
        :style="`background-image: url('/img/enterprises/${unity.codPessEmpr}.png')`"
      >
        <div id="bg-transparent"></div>
        <div class="card-body">
          <div class="row d-flex align-itens-center">
            <div class="col-10">
              <p class="mb-1">
                <small>{{ unity.emprName }}</small>
              </p>
              <p class="mb-0">{{ unity.unityName }}</p>
              <h6 class="mb-1">{{ unity.subUnityName }}</h6>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <button
                @click="selectUnity(unity)"
                class="btn bg-secondary bg-opacity-10 text-secondary lh-1 rounded-pill p-2 me-2"
              >
                <i class="ph-check"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#bg-current {
  background-position: center;
  background-size: cover;
}

#bg-transparent {
  color-scheme: light;
  background-color: #fff;
}
#bg-transparent[data-color-theme='dark'],
[data-color-theme='dark'] #bg-transparent:not([data-color-theme]),
html[data-color-theme='dark'] .card {
  color-scheme: dark;
  background-color: #2c2d33;
}
#bg-transparent {
  color-scheme: light;
  border-radius: var(--card-border-radius);
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.card-body {
  z-index: 2;
}
</style>
<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'SelectUnity',

  props: {
    unities: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {}
  },

  methods: {
    async selectUnity(unity) {
      const { user } = this.$store.getters
      user.unity = unity
      const response = await this.$store.dispatch('setUnity', user)
      this.$root.$refs.notification.success('Unidade selecionada!')
      this.$router.push(PATHS.HOME)
    },
  },
}
</script>

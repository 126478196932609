<template>
  <form class="card" @submit.prevent="newPassword">
    <div class="card-header d-flex align-items-center py-0">
      <h6 class="py-3 mb-0"><i class="ph-key me-2" />Atualizar Senha</h6>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 col-md-4 mb-2">
          <label class="form-label">Senha Antiga</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': v$.oldPassword.$errors.length }"
              placeholder="•••••••••••"
              v-model="v$.oldPassword.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted" />
            </div>
            <span
              v-if="v$.oldPassword.required.$invalid"
              class="invalid-feedback"
            >
              O campo é obrigatório
            </span>
            <span
              v-if="v$.oldPassword.minLength.$invalid"
              class="invalid-feedback"
            >
              A senha deve ter pelo menos
              {{ v$.oldPassword.minLength.$params.min }} caracteres
            </span>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-2">
          <label class="form-label">Nova Senha</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              :class="{ 'is-invalid': v$.password.$errors.length }"
              placeholder="•••••••••••"
              v-model="v$.password.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted" />
            </div>
            <span v-if="v$.password.required.$invalid" class="invalid-feedback">
              O campo é obrigatório
            </span>
            <span
              v-if="v$.password.minLength.$invalid"
              class="invalid-feedback"
            >
              A senha deve ter pelo menos
              {{ v$.password.minLength.$params.min }} caracteres
            </span>
          </div>
        </div>
        <div class="col-12 col-md-4 mb-2">
          <label class="form-label">Confirme a Senha</label>
          <div class="form-control-feedback form-control-feedback-start">
            <input
              type="password"
              class="form-control"
              :class="{
                'is-invalid': v$.confirmPassword.$errors.length,
              }"
              placeholder="•••••••••••"
              v-model="v$.confirmPassword.$model"
            />
            <div class="form-control-feedback-icon">
              <i class="ph-lock text-muted" />
            </div>
            <span
              v-if="v$.confirmPassword.sameAsPassword.$invalid"
              class="invalid-feedback"
            >
              As senhas devem ser idênticas
            </span>
          </div>
        </div>
        <div class="text-end">
          <button
            type="submit"
            class="btn btn-primary"
            title="Criar Nova Senha"
            :disabled="v$.$invalid || disableButton"
          >
            <span v-if="disableButton">
              <i class="ph-spinner spinner" />
            </span>
            <span v-else>Atualizar Senha</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, sameAs } from '@vuelidate/validators'
import PATHS from '@/router/routesMap'

export default {
  name: 'UpdatePassword',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      disableButton: false,
    }
  },

  methods: {
    async newPassword() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch('updatePassword', {
            uuid: this.user.uuid,
            oldPassword: this.oldPassword,
            password: this.password,
            confirmPassword: this.confirmPassword,
          })

          this.resetForm()

          if (response && response.message) {
            this.$root.$refs.notification.success(response.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        } catch (error) {
          this.resetForm()

          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },

    resetForm() {
      this.disableButton = false
      this.oldPassword = ''
      this.password = ''
      this.confirmPassword = ''
      this.v$.$reset()
    },
  },

  validations() {
    return {
      oldPassword: {
        required,
        minLength: minLength(6),
      },
      password: {
        required,
        minLength: minLength(6),
      },
      confirmPassword: {
        sameAsPassword: sameAs(this.password),
      },
    }
  },
}
</script>

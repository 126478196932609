<template>
  <LoadingData v-if="loadData" />
  <div v-else>
    <div class="card">
      <div class="card-header d-flex align-items-center py-0">
        <h6 class="py-3 mb-0">
          <i class="ph-buildings me-2" />Selecionar Unidade
        </h6>
      </div>
      <div class="card-body">
        <SelectUnity :unities="unities"></SelectUnity>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingData from '@/components/commons/LoadingData.vue'
import SelectUnity from '@/components/unity/SelectUnity.vue'

export default {
  name: 'Unitites',

  components: {
    LoadingData,
    SelectUnity,
  },

  data() {
    return {
      loadData: true,
      unities: [],
    }
  },

  methods: {
    async getUnities() {
      try {
        this.unities = await this.$store.dispatch(
          'getUnities',
          this.$store.getters.user.id,
        )
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getUnities()
  },
}
</script>
